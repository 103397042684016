import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Modal } from "../../components";
import InputField from "../../components/atom/FormInput/InputField";
// import { assign_staff, create_customers, get_customers } from "../../redux/customerSlice";
import Select from "react-select";

import { ReactMultiSelect } from "../../components/atom/MultiReactSelect";
import { add_menu, get_permission, update_menu } from "../../redux/menuSlice";
import { add_role, get_role, update_role } from "../../redux/roleSlice";
import DotsLoader from "../../components/atom/DotsLoader";
function AddRole({ editingRecord, onCancelForm, modalTitle }) {
  const { isLoading, permission } = useSelector((state) => state.menu);
  // const { salepersons } = useSelector((state) => state.saleperson);
  const [selected, setSelected] = React.useState(
    editingRecord?.permissions ? editingRecord?.permissions : []
  );
  const dispatch = useDispatch();
  let initialData = {
    name: "",
  };
  useEffect(() => {
    dispatch(get_permission());
  }, []);
  if (editingRecord) {
    const { id, name } = editingRecord;
    initialData = {
      id,
      name,
    };
  }
  const [data, setData] = useState(initialData);
  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };
  // const options = menu.map((item)  => (

  //  {
  //   value:  [...item.permissions],
  //  label:  item.permissions[0],
  //   //  permission:[...item.permissions]
  //   }

  // ));

  const onSubmit = async (e) => {
    e.preventDefault();

    let arr = [];
    // eslint-disable-next-line
    selected?.map((val) => {
      arr = [...arr, val?.id];
    });
    if (!editingRecord) {
      try {
        const res = await dispatch(add_role({ ...data, permissions: arr }));
        if (res.payload.status === 201) {
          toast.success("Menu Added Successfully");
          dispatch(get_role());
          onCancelForm();
        }
      } catch (error) {
        toast.error("menu couldn't be added");
        console.log("~ file: AddImage.jsx:111 ~ onSubmit ~ error", error);
      }
    } else {
      try {
        const res = await dispatch(
          update_role({ id: data?.id, data: { ...data, permissions: arr } })
        );
        if (res.payload.status === 201) {
          toast.success("Menu Updated Successfully");
          dispatch(get_role());
          onCancelForm();
        }
      } catch (error) {
        toast.error("menu couldn't be updated");
        console.log("~ file: AddImage.jsx:111 ~ onSubmit ~ error", error);
      }
    }
  };
  // const getOptionValue = () => {
  //   return options.map((value)=>value);
  // };

  return (
    <Modal
      open={true}
      handleModal={onCancelForm}
      cancelButtonRef={onCancelForm}
      handleModalSubmit={onSubmit}
      className="max-w-2xl"
      title={editingRecord ? "Edit Role" : "Add Role"}
      modalType={editingRecord ? "editUser" : "newUser"}
    >
      {isLoading&&<DotsLoader/>}
      <div className="grid lg:grid-cols-2 gap-4">
        <div className="w-full">
          <label className="inputLabel" htmlFor="company">
            Name
          </label>
          <InputField
            name="name"
            type="text"
            placeholder="Enter Name"
            value={data.name}
            onChange={handleInput}
            // onChange={({ target: { value } }) => setLabel(value)}
            // onBlur={onBlur}
          />
        </div>
        <div className="w-full">
          <label className="inputLabel" htmlFor="salepersons">
            Select Permissions
          </label>
          <ReactMultiSelect
            options={permission}
            value={selected}
            onChange={setSelected}
            labelProps={"description"}
            valueProps={"id"}
            placeholder="Select Permission"
            className="mt-[4px]"
            name="permission"
            label="Permission"
          />
        </div>
      </div>
    </Modal>
  );
}

export default AddRole;
