import React from "react";

const Checkbox = ({ label, checked, onChange, className, boxStyle }) => {
  return (
    <label className={`flex items-center gap-2 cursor-pointer ${className}`}>
      <input
        type="checkbox"
        className={`form-checkbox text-secondary cursor-pointer h-3 w-3 ${boxStyle}`}
        checked={checked}
        onChange={onChange}
      />
      <span>{label}</span>
    </label>
  );
};

export default Checkbox;
