import React from "react";

import {
  Bars3BottomLeftIcon,
  Bars3Icon,
  BellAlertIcon,
  ChatBubbleBottomCenterTextIcon,
  ShoppingCartIcon,
} from "@heroicons/react/24/outline";

import { UserCircleIcon } from "@heroicons/react/24/solid";
import EclipseDropdown from "../../Menu/EclipseDropdown";
import SearchBox from "../../common/SearchBox";
import DropdownModal from "../../common/DropdownModal";
import Cart from "../../Cart";
import Topbar from "../../Menu/Topbar";
import { new_logo } from "../../../assets/images";


export default function DashboardHead({
  show_navlabel,
  toggleNavbar,
  toggleDarkMode,
  darkToggle,
  setUser,
  user,
}) {
  return (
    <div className="fixed top-0 w-full z-40">
      <header
        className={
          show_navlabel
            ? "grid grid-cols-body "
            : "grid grid-cols-bodyClosed dark:bg-blue bg-white dark:text-white border-b dark:border-white/10"
        }
      >
        {show_navlabel ? (
          <div className="flex px-5 pt-5 items-center justify-between">
            {show_navlabel && (
              <>
                {darkToggle ? (
                  <img src="/logoWhite.png" className="h-12" alt="" />
                ) : (
                  <img src={new_logo} className="h-12" alt="" />
                )}
              </>
            )}
            <Bars3Icon
              onClick={toggleNavbar}
              className="cursor-pointer w-6 dark:text-white"
            />
          </div>
        ) : (
          <div className="flex items-center justify-center ml-4">
            <Bars3BottomLeftIcon
              onClick={toggleNavbar}
              className="cursor-pointer w-6"
            />
          </div>
        )}
        {show_navlabel ? (
          <div className="flex items-center justify-between w-full bg-white dark:bg-blue px-5 h-full ">
            <div className="flex items-center space-x-4">
              <div className="flex flex-col justify-center">
                <p className="text-xl font-bold">Dashboard</p>
                <p className="text-gray-500 w-32">Robo Anywhere</p>
              </div>
              <SearchBox className="w-72 border border-gray-300 rounded" />
            </div>
            <div className="flex items-center justify-end">
              <span className="flex items-center space-x-4 mr-6">
                <DropdownModal
                  Icon={ChatBubbleBottomCenterTextIcon}
                  title={undefined}
                >
                  <div>Messages</div>
                </DropdownModal>
                <DropdownModal title={undefined} Icon={BellAlertIcon}>
                  <div>Notifications</div>
                </DropdownModal>
                <DropdownModal title={undefined} Icon={ShoppingCartIcon}>
                  <Cart />
                </DropdownModal>
              </span>
              <div className="flex">
                <UserCircleIcon className="w-12 mr-1 text-secondary" />
                <span>
                  <p className="font-semibold text-lg">John Doye</p>
                  <p className="text-sm -mt-1 text-gray-400">user@gmail.com</p>
                </span>
              </div>
              <EclipseDropdown
                darkToggle={darkToggle}
                toggleDarkMode={toggleDarkMode}
              />
            </div>
          </div>
        ) : (
          <Topbar
            darkToggle={darkToggle}
            setUser={setUser}
            show_navlabel={undefined}
            toggleDarkMode={toggleDarkMode}
          />
        )}
      </header>
    </div>
  );
}
