import { Link } from "react-router-dom";
import Dropdown from "./Dropdown";
import { sideMenu } from "./Menu";
import { useEffect, useState } from "react";
import {
  ChatBubbleBottomCenterIcon,
  UserCircleIcon,
} from "@heroicons/react/24/solid";
import DropdownModal from "../common/DropdownModal";
import Cart from "../Cart";
import {
  BellAlertIcon,
  ChatBubbleBottomCenterTextIcon,
  ShoppingCartIcon,
} from "@heroicons/react/24/outline";
import EclipseDropdown from "./EclipseDropdown";
import { new_logo } from "../../assets/images";

const Topbar = ({ show_navlabel, toggleDarkMode, darkToggle }) => {
  const [activeMenuItem, setActiveMenuItem] = useState("Dashboard");

  //to keep track of the active menu item even page refreshes
  useEffect(() => {
    const path = window.location.pathname;
    const label = sideMenu.find((item) => item?.to === path);
    if (label) {
      setActiveMenuItem(label.label);
    }
  }, []);

  return (
    <div className="flex items-center justify-between w-full py-2 px-5">
      <>
        {darkToggle ? (
          <img src="/logoWhite.png" className="h-10" alt="" />
        ) : (
          <img src={new_logo} className="h-10" alt="" />
        )}
      </>
      <div className="flex items-center">
        {sideMenu.map((item, key) => {
          const { label, to, Icon, children } = item;
          const isActive = activeMenuItem === item.label;
          return (
            <div key={key} className="sidebar-item">
              {item?.children ? (
                <Dropdown
                  show_navlabel={show_navlabel}
                  label={label}
                  activeMenuItem={activeMenuItem}
                  setActiveMenuItem={setActiveMenuItem}
                  to={to}
                  options={children}
                />
              ) : (
                <Link
                  className={`pageLink hover:bg-blue/10 dark:hover:bg-white/10 ${isActive && "bg-secondary text-white hover:bg-secondary px-4"}`}
                  title={label}
                  to={to}
                  onClick={() => setActiveMenuItem(item.label)}
                >
                  <span className="ml-1">{label}</span>
                </Link>
              )}
            </div>
          );
        })}
      </div>
      <div className="flex items-center justify-end">
        <span className="flex items-center space-x-4 mr-6">
          {/* <DropdownModal
            Icon={ChatBubbleBottomCenterTextIcon}
            title={undefined}
          >
            <div>Messages</div>
          </DropdownModal>
          <DropdownModal title={undefined} Icon={BellAlertIcon}>
            <div>Notifications</div>
          </DropdownModal> */}
          <DropdownModal title={undefined} Icon={ShoppingCartIcon}>
            <Cart />
          </DropdownModal>
        </span>
        <div className="flex">
          <UserCircleIcon className="w-12 mr-1 text-secondary" />
          <span>
            <p className="font-semibold text-lg">John Doye</p>
            <p className="text-sm -mt-1 text-gray-400">user@gmail.com</p>
          </span>
        </div>
        <EclipseDropdown
          darkToggle={darkToggle}
          toggleDarkMode={toggleDarkMode}
        />
      </div>
    </div>
  );
};

export default Topbar;
