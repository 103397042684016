import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

export default function Dropdown(props) {
  const {
    label,
    options,
    Icon,
    show_navlabel,
    activeMenuItem,
    setActiveMenuItem,
  } = props;

  return (
    <Menu as="div" className="drop_down_menu">
      <div>
        <Menu.Button
          title={label}
          className="pageLink hover:bg-blue/10 dark:hover:bg-white/10 justify-between"
        >
          <div
            className={
              show_navlabel
                ? "flex items-center w-full "
                : "flex items-center w-full justify-center"
            }
          >
            {Icon && <Icon />}
            {/* {show_navlabel && <span className="ml-3">{label}</span>} */}
            <span
              className={show_navlabel ? "ml-3 capitalize" : "ml-1 capitalize "}
            >
              {label}
            </span>
          </div>
          <ChevronDownIcon className="w-4" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={
            show_navlabel
              ? "dropdown_options bg-beta rounded-xl pl-5"
              : "dropdown_options_small bg-white dark:bg-blue dark:shadow-black/30 shadow-lg p-3 rounded-xl absolute top-0 mt-12 text-black"
          }
        >
          {options?.map((item, index) => {
            const isActive = activeMenuItem === item.label;

            return (
              <div key={index}>
                <Link
                  className={`pageLink py-1 dark:text-white ${
                    show_navlabel && "dark:hover:text-white hover:bg-blue/10"
                  } ${isActive ? "bg-secondary" : ""}`}
                  title={item.label}
                  to={`${item.to}`}
                  onClick={() =>
                    setActiveMenuItem && setActiveMenuItem(item.label)
                  }
                >
                  {Icon && <item.Icon />}
                  <span className="ml-3 capitalize">{item.label}</span>
                  {/* {show_navlabel && <span className="ml-3">{item.label}</span>} */}
                </Link>
              </div>
            );
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
