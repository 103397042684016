import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  
  getMenu,
  addMenu,deleteMenu,updateMenu, getPermission
} from "../services";


const initialState = {
  isError: false,
  isLoading: false,
  menu: [],
  permission:[],
  message: "",
  isLoader: false,
};
// Get Menu Data
export const get_menu = createAsyncThunk(
  "get_menu",
  async (thunkAPI) => {
    try {
      return await getMenu();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Add New User
export const add_menu = createAsyncThunk(
  "add_menu",
  async (data, thunkAPI) => {
    try {
      return await addMenu(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// update 
export const update_menu = createAsyncThunk(
  "update_menu",
  async (data, thunkAPI) => {
    try {
      return await updateMenu(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete User
export const delete_menu = createAsyncThunk(
  "delete_menu",
  async (id, thunkAPI) => {
    try {
      return await deleteMenu(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_permission = createAsyncThunk(
    "get_permission",
    async (thunkAPI) => {
      try {
        return await getPermission();
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.customers = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_menu.pending, (state) => {
        state.isLoading = true;
        state.isLoader = true;
        state.menu = [];
      })
      .addCase(get_menu.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoader = false;
        state.menu = action.payload?.data;
      })
      .addCase(get_menu.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoader = false;
        state.isError = true;
        state.message = action.payload;
        state.menu = [];
      })
      .addCase(add_menu.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(add_menu.fulfilled, (state, action) => {
        state.isLoading = false;
        state.menu.push(action.payload.data);
      })
      .addCase(add_menu.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_menu.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_menu.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.menu.push(action?.payload?.data)
        const result = state.menu.findIndex(
            ({ id }) => id === action?.payload?.data?.id
          );
          state.menu[result] = {
            ...state.menu[result],
            ...action.payload.data,
          };
      })
      .addCase(update_menu.rejected, (state, action) => {
        
        state.isLoading = false;
      })
      .addCase(delete_menu.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_menu.fulfilled, (state, action) => {
        state.isLoading = false;
        // const result = state.menu.findIndex(
        //   ({ id }) => id === action?.payload?.data?.id
        // );
        // state.menu[result].active = false;
      })
      .addCase(delete_menu.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_permission.pending, (state) => {
        state.isLoading = true;
        state.isLoader = true;
        // state.permission = [];
      })
      .addCase(get_permission.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoader = false;
        state.permission = action.payload?.data;
      })
      .addCase(get_permission.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoader = false;
        state.isError = true;
        state.message = action.payload;
        state.permission = [];
      });
  },
});

export const { reset } = menuSlice.actions;
export default menuSlice.reducer;
