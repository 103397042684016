import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import menuSlice from "./menuSlice";
import roleSlice from "./roleSlice";
import userSlice from "./userSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    menu:menuSlice,
    user:userSlice,
    role:roleSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
