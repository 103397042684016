import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { new_logo } from "../../assets/images";
import InputField from "../../components/atom/FormInput/InputField";
import { login } from "../../redux/authSlice";

export default function ForgetPassword() {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");

  const submitHandler = async (event) => {
    event.preventDefault();
    const userData = {
      login: username,
    };
    dispatch(login(userData));
  };
  return (
    <div className="flex w-full bg-login items-center justify-center min-h-screen">
      <div className="bg-white w-full lg:max-w-xl p-8 rounded-md shadow-lg flex items-center flex-col">
        <img src={new_logo} alt="" />
        <form onSubmit={submitHandler} className="w-full mt-4 space-y-4">
          <InputField
            name="email"
            label="Email"
            type="text"
            placeholder="Enter your email address"
            value={username}
            onChange={({ target: { value } }) => setUsername(value)}
            // onBlur={onBlur}
          />
            <NavLink to="/login" className="text-yellow-500 text-sm mt-3 text-end underline hover:text-yellow-600 cursor-pointer">
              Remember your account?
            </NavLink>

          <span className="w-full flex justify-center mt-2">
            <button className="btnPrimary px-7" type="submit">
              Register
            </button>
          </span>
        </form>
      </div>
      <Toaster position="top-right" />
    </div>
  );
}
