import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Modal } from "../../components";
import InputField from "../../components/atom/FormInput/InputField";
// import { assign_staff, create_customers, get_customers } from "../../redux/customerSlice";
import Select from "react-select";

import { ReactMultiSelect } from "../../components/atom/MultiReactSelect";
import {
  add_menu,
  get_menu,
  get_permission,
  update_menu,
} from "../../redux/menuSlice";
import DotsLoader from "../../components/atom/DotsLoader";
function AddMenu({ editingRecord, onCancelForm, modalTitle }) {
  const { isLoading, menu, permission } = useSelector((state) => state.menu);
  // const { salepersons } = useSelector((state) => state.saleperson);
  const [selected, setSelected] = React.useState(
    editingRecord?.permissions ? editingRecord?.permissions : []
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(get_permission());
  }, []);
  let initialData = {
    parentId: 0,
    label: "",
    permissions: [],
  };
  if (editingRecord) {
    const { id, label, parentId } = editingRecord;
    initialData = {
      id,
      parentId,
      label,
    };
  }
  const [data, setData] = useState(initialData);
  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  // options?.map((item)=>{
  //   item.map((ele)=>{
  //     console.log("element",ele)
  //   })
  //   // console.log("itemm",item)
  // })
  // console.log("opttts",opt)

  const onSubmit = async (e) => {
    e.preventDefault();
    let arr = [];
    // eslint-disable-next-line
    selected?.map((val) => {
      arr = [...arr, val?.id];
    });

    if (!editingRecord) {
      try {
        const res = await dispatch(add_menu({ ...data, permissions: arr }));
        if (res.payload.status === 201) {
          toast.success("Menu Added Successfully");
          dispatch(get_menu());
          onCancelForm();
        }
      } catch (error) {
        toast.error("menu couldn't be added");
        console.log("~ file: AddImage.jsx:111 ~ onSubmit ~ error", error);
      }
    } else {
      try {
        const res = await dispatch(update_menu({ ...data, permissions: arr }));
        if (res.payload.status === 201) {
          toast.success("Menu Updated Successfully");
          dispatch(get_menu());
          onCancelForm();
        }
      } catch (error) {
        toast.error("menu couldn't be updated");
        console.log("~ file: AddImage.jsx:111 ~ onSubmit ~ error", error);
      }
    }
  };
  // const getOptionValue = () => {
  //   return options.map((value)=>value);
  // };

  return (
    <Modal
      open={true}
      handleModal={onCancelForm}
      cancelButtonRef={onCancelForm}
      handleModalSubmit={onSubmit}
      className="max-w-2xl"
      title={"Add Menu"}
      modalType={editingRecord ? "editUser" : "newUser"}
    >
      {isLoading&&<DotsLoader/>}
      <div className="grid grid-cols-2 gap-3 ">
        <div className="w-full">
          <label className="inputLabel" htmlFor="company">
            Add Label
          </label>
          <InputField
            name="label"
            //label="Label"
            type="text"
            placeholder="Enter label"
            value={data.label}
            onChange={handleInput}
            // onChange={({ target: { value } }) => setLabel(value)}
            // onBlur={onBlur}
          />
        </div>

        <div className="w-full">
          <label className="inputLabel" htmlFor="Select Menu">
            Select Menu
          </label>
          <Select
            options={menu}
            value={
              data?.parentId
                ? {
                    id: data?.parentId,
                    label: menu?.find(({ id }) => id === data?.parentId)?.label,
                  }
                : null
            }
            getOptionLabel={(options) => options?.label}
            getOptionValue={(options) => options?.id}
            onChange={(e) => setData({ ...data, parentId: e?.id })}
            placeholder="Select Menu"
            className="mt-[5px]"
            name="id"
            label="Select Menu"
            // menuPosition="fixed"
          />
        </div>
        <div className="w-full">
          <label className="inputLabel" htmlFor="salepersons">
            Select Permission
          </label>
          <ReactMultiSelect
            options={permission}
            value={selected}
            onChange={setSelected}
            labelProps={"description"}
            valueProps={"id"}
            placeholder="Select Permission"
            className="mt-[4px]"
            name="permission"
            label="Permission"
          />
        </div>
      </div>
    </Modal>
  );
}

export default AddMenu;
