import React from "react";
import {
  GlobeAsiaAustraliaIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/24/outline";
import Checkbox from "../../components/common/CheckBox";
import { SelectBox, Status } from "../../components";
import { user1 } from "../../assets/images";

const domains = [
  "towingnearme.us",
  "carpet-cleaning.us",
  "handman.us",
  "movers-nearme.us",
];

const platforms = [
  {
    title: "Parked Domains",
    value: "9582",
  },
  {
    title: "Hosted Domains",
    value: "12",
  },
  {
    title: "LeadGen Domains",
    value: "0",
  },
];

const performance = [
  {
    title: "Earnings Total",
    growth: "+12.1%",
    value: "$3,500.71",
  },
  {
    title: "Subtotal",
    growth: "+4.82%",
    value: "$2,980.45",
  },
  {
    title: "New  volume from sales",
    growth: "+4.82%",
    value: "$950.00",
  },
];

export default function Main() {
  return (
    <div className="p-7">
      <div className="flex items-center justify-between">
        <div>
          <h3 className="text-2xl font-bold">Dashboard</h3>
          <p className="text-gray-400">Dashboard</p>
        </div>
        <div className="flex items-center justify-end gap-2">
          <button className="btnPrimary">Reports</button>
          <button
            onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}
            className="btnPrimary bg-white text-primary"
          >
            Sign Out
          </button>
        </div>
      </div>
      <div className="grid grid-cols-dashboard gap-7 mt-6">
        <div className="card">
          <h5 className="font-bold">Your domains</h5>
          <p className="text-gray-500 font-bold">24445</p>
          <div className="flex gap-5 mt-5">
            <img src={user1} alt="" className="w-16 h-16" />
            <div>
              <h2 className="text-4xl font-semibold">Jackson</h2>
              <p className="text-gray-400">Account # 000999222092</p>
              <button className="btnPrimary mt-3">View Profile</button>
            </div>
          </div>
          <div className="flex items-center gap-4 mt-7">
            <div className="p-3 rounded-md bg-dark text-white">
              <GlobeAsiaAustraliaIcon className="w-7" />
            </div>
            <div className="flex-1 flex items-center justify-between gap-4">
              <div>
                <h2 className="text-lg font-bold">Internal</h2>
                <p className="text-gray-500">Most Successfull Fellas</p>
              </div>
              <div className="p-2 text-white bg-dark rounded-md">+825</div>
            </div>
          </div>
          <div className="flex items-center gap-4 mt-7">
            <div className="p-3 rounded-md bg-dark text-white">
              <PaperAirplaneIcon className="w-7" />
            </div>
            <div className="flex-1 flex items-center justify-between gap-4">
              <div>
                <h2 className="text-lg font-bold">External</h2>
                <p className="text-gray-500">Most Successfull Fellas</p>
              </div>
              <div className="p-2 text-white bg-dark rounded-md">+825</div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="flex justify-between w-full">
            <div>
              <h3 className="text-2xl font-bold">Domains</h3>
              <p className="text-gray-400">500</p>
            </div>
            <div className="flex items-center justify-end gap-2">
              <SelectBox
                options={["Last 7 days", "Last 30 days", "1 year ago"]}
              />
              <button className="btnPrimary">Download Invoice</button>
            </div>
          </div>
          <table className="min-w-full divide-y divide-gray-200 mt-4">
            <thead className="bg-gray-100 dark:bg-blue shadow-sm ">
              <tr>
                <th scope="col" className="tableHead">
                  <Checkbox boxStyle="h-4 w-4" />
                </th>
                <th scope="col" className="tableHead">
                  Domain Name
                </th>
                <th scope="col" className="tableHead">
                  Date & Time
                </th>
                <th scope="col" className="tableHead">
                  Payment
                </th>
                <th scope="col" className="tableHead">
                  Country
                </th>
                <th scope="col" className="tableHead">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-gray-600 h-full overflow-y-scroll">
              {domains.map((domain) => (
                <tr className="hover:bg-gray-200 dark:hover:bg-blue/70">
                  <td className="p-3">
                    <div className="text-sm">
                      <Checkbox boxStyle="h-4 w-4" />
                    </div>
                  </td>
                  <td className="p-3">
                    <div className="text-sm">
                      <p className="font-semibold">{domain}</p>
                      <p className="text-gray-400">1 year validity</p>
                    </div>
                  </td>
                  <td className="p-3">
                    <div className="text-sm">
                      <p className="font-semibold">2 May 2023</p>
                      <p className="text-gray-400">09:00 am</p>
                    </div>
                  </td>
                  <td className="p-3">
                    <div className="text-sm">
                      <p className="font-semibold">$200</p>
                    </div>
                  </td>
                  <td className="p-3">
                    <div className="text-sm">
                      <p className="font-semibold">UK</p>
                      <p className="text-gray-400">California</p>
                    </div>
                  </td>
                  <td className="p-3 text-sm">
                    <Status type="success" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="mt-7 grid grid-cols-3 gap-7">
        <div className="card">
          <h3 className="text-2xl font-bold">Domain Marketplace</h3>
          <p className="text-gray-400">Market Status</p>
          <img
            src="./DomainMarketPlace.png"
            className="ml-auto mr-auto mt-5"
            alt=""
          />
          <ul className="w-full mt-5 text-gray-500">
            <li className="flex items-center justify-between">
              <p>Martketplace Listing</p>
              <p>10,767</p>
            </li>
            <li className="flex items-center justify-between">
              <p>Rental & Purchase Plans</p>
              <p>48</p>
            </li>
            <li className="flex items-center justify-between">
              <p>Available Pages</p>
              <p>86</p>
            </li>
          </ul>
        </div>
        <div className="card">
          <h3 className="text-2xl font-bold">Portfolio performance</h3>
          <p className="text-gray-400">Avg Project Bill</p>
          <img src="./circle.png" alt="" className="mr-auto ml-auto" />
          <table className="w-full">
            <tbody className="divide-y divide-gray-500">
              {performance.map((item, index) => (
                <tr>
                  <td>
                    <div className="py-3">{item.title}</div>
                  </td>
                  <td>
                    <div className="py-3 px-3 text-green-500">
                      {item.growth}
                    </div>
                  </td>
                  <td>
                    <div className="py-3 pl-3 text-end">{item.value}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="card">
          <h3 className="text-2xl font-bold">Platforms</h3>
          <p className="text-gray-400">Avg Project Bill</p>
          <table className="w-full mt-5">
            <tbody className="divide-y divide-gray-500">
              {platforms.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className="py-3">{item.title}</div>
                  </td>
                  <td>
                    <div className="py-3 pl-3 text-end">{item.value}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <img
            src="./platformsGraph.png"
            alt=""
            className="w-full mt-8 rounded"
          />
        </div>
      </div>
    </div>
  );
}
