import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Modal } from "../../components";
import InputField from "../../components/atom/FormInput/InputField";
// import { assign_staff, create_customers, get_customers } from "../../redux/customerSlice";
import Select from "react-select";

import { ReactMultiSelect } from "../../components/atom/MultiReactSelect";
import { add_menu, update_menu } from "../../redux/menuSlice";
import { update_users } from "../../redux/userSlice";
import { get_role } from "../../redux/roleSlice";
import DotsLoader from "../../components/atom/DotsLoader";
function AddUser({ editingRecord, onCancelForm, modalTitle }) {
  const { isLoading, users } = useSelector((state) => state.user);
  const { role } = useSelector((state) => state.role);
  // const { salepersons } = useSelector((state) => state.saleperson);
  const [selected, setSelected] = React.useState(
    editingRecord ? editingRecord?.roles : []
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(get_role());
  }, []);
  let initialData = {
    roles: [],
  };

  if (editingRecord) {
    const { id, name, roles } = editingRecord;
    initialData = {
      id,
      roles,
    };
  }
  const [data, setData] = useState(initialData);
  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    let arr = [];
    // eslint-disable-next-line
    selected?.map((val) => {
      arr = [...arr, val.id];
    });
    // eslint-disable-next-line
    // selected?.map((val) => {
    //   [...selected, val];
    // });
    if (!editingRecord) {
      // try {
      //   const res = await dispatch(add_menu(data));
      //   if (res.payload.status === 200) {
      //     toast.success("Sale Person assigned Successfully");
      //   //   dispatch(get_customers());
      //     onCancelForm();
      //   }
      // } catch (error) {
      //   toast.error("menu couldn't be added");
      //   console.log("~ file: AddImage.jsx:111 ~ onSubmit ~ error", error);
      // }
      console.log("add user");
    } else {
      try {
        const res = await dispatch(update_users({ ...data, roles: arr }));
        if (res.payload.status === 200) {
          toast.success("User Updated Successfully");
          //   dispatch(get_customers());
          onCancelForm();
        }
      } catch (error) {
        toast.error("User couldn't be updated");
        console.log("~ file: AddImage.jsx:111 ~ onSubmit ~ error", error);
      }
    }
  };
  // const getOptionValue = () => {
  //   return options.map((value)=>value);
  // };

  return (
    <Modal
      open={true}
      handleModal={onCancelForm}
      cancelButtonRef={onCancelForm}
      handleModalSubmit={onSubmit}
      className="max-w-2xl"
      title={editingRecord ? "Edit User" : "Add User"}
      modalType={editingRecord ? "editUser" : "newUser"}
    >
      {isLoading && <DotsLoader />}
      <div className="grid lg:grid-cols-2 gap-4">
        {/* <div className="w-full">
          <label className="inputLabel" htmlFor="company">
            {"Companies"}
          </label>
          <InputField
            name="user"
            label="User"
            type="text"
            placeholder="Enter User"
            value={data.name}
            onChange={handleInput}
            // onChange={({ target: { value } }) => setLabel(value)}
            // onBlur={onBlur}
          />
          
        </div> */}
        <div className="w-full">
          <label className="inputLabel" htmlFor="salepersons">
            Select Roles
          </label>
          <ReactMultiSelect
            options={role}
            value={selected}
            //       getOptionLabel={(option) => option.label}
            // getOptionValue={(option) => option.value}
            onChange={setSelected}
            labelProps={"name"}
            valueProps={"id"}
            //   menuPosition="fixed"
            // defaultValue={
            //   selected
            //     ? selected?.map((ele) => ({
            //         id: ele,
            //         name: ele,
            //       }))
            //     : null
            // }
            placeholder="Select roles"
            className="!mb-2"
            name="role"
            label="Role"
          />
        </div>
      </div>
    </Modal>
  );
}

export default AddUser;
