import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRole,addRole,updateRole,deleteRole
} from "../services";


const initialState = {
  isError: false,
  isLoading: false,
  role: [],
  message: "",
  isLoader: false,
};
// Get Menu Data
export const get_role = createAsyncThunk(
  "get_role",
  async (thunkAPI) => {
    try {
      return await getRole();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Add New User
export const add_role = createAsyncThunk(
  "add_role",
  async (data, thunkAPI) => {
    try {
      return await addRole(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Assign Staff
export const update_role = createAsyncThunk(
  "update_role",
  async (data, thunkAPI) => {
    try {
      return await updateRole(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete User
export const delete_role = createAsyncThunk(
  "delete_role",
  async (id, thunkAPI) => {
    try {
      return await deleteRole(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.customers = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_role.pending, (state) => {
        state.isLoading = true;
        state.isLoader = true;
        state.role = [];
      })
      .addCase(get_role.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoader = false;
        state.role = action.payload?.data;
      })
      .addCase(get_role.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoader = false;
        state.isError = true;
        state.message = action.payload;
        state.role = [];
      })
      .addCase(add_role.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(add_role.fulfilled, (state, action) => {
        state.isLoading = false;
        state.role.push(action.payload.data);
      })
      .addCase(add_role.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_role.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_role.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.role.push(action?.payload?.data)
        const result = state.role.findIndex(
            ({ id }) => id === action?.payload?.data?.id
          );
          state.role[result] = {
            ...state.role[result],
            ...action.payload.data,
          };
      })
      .addCase(update_role.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_role.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_role.fulfilled, (state, action) => {
        state.isLoading = false;
        // const result = state.menu.findIndex(
        //   ({ id }) => id === action?.payload?.data?.id
        // );
        // state.menu[result].active = false;
      })
      .addCase(delete_role.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { reset } = roleSlice.actions;
export default roleSlice.reducer;
