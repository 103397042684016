import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

const DropdownModal = ({
  title,
  Icon,
  className = "",
  children,
  modalStyle = "",
}) => {
  return (
    <Menu as="div" className="relative">
      <Menu.Button className={`flex items-center gap-2 ${className}`}>
        {Icon && <Icon className="w-6" />} {title && title}
        {title && <ChevronDownIcon className="w-4" />}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute right-0 top-0 mt-7 bg-white p-5 z-20 shadow-lg shadow-black/15 rounded-lg w-72 ${modalStyle}`}
        >
          {children}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default DropdownModal;
