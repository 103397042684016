import { Children, cloneElement, useState } from "react";
import { useSelector } from "react-redux";
import Sidebar from "../components/Menu/Sidebar";
import DashboardHead from "../components/molecules/Header";
import Login from "../views/Login";


export default function Layout({ isLoading, children, setUser }) {
  const { user } = useSelector((state) => state.auth);
  console.log("🚀 ~ file: index.jsx:10 ~ Layout ~ user:", user)
  const [show_navlabel, setShowNavbar] = useState(true);
  const [darkToggle, setDarkToggle] = useState(false);

  const toggleNavbar = () => {
    setShowNavbar(!show_navlabel);
  };

  const toggleDarkMode = () => {
    setDarkToggle(!darkToggle);
  };

  return (
    <div className={`${darkToggle && "dark bg-dark"}`}>
      {isLoading ? (
        <div className="flex flex-col w-full justify-center text-center h-screen">
          Loading...
        </div>
      ) : user ? (
        <>
          <DashboardHead
            toggleNavbar={toggleNavbar}
            show_navlabel={show_navlabel}
            toggleDarkMode={toggleDarkMode}
            darkToggle={darkToggle}
            setUser={setUser}
          />
          <div
            className={
              show_navlabel
                ? "grid grid-cols-body h-screen"
                : "w-full min-h-screen scroll-container"
            }
          >
            {show_navlabel && (
              <Sidebar
                show_navlabel={show_navlabel}
                toggleDarkMode={toggleDarkMode}
                darkToggle={darkToggle}
              />
            )}
            <div
              className={
                show_navlabel
                  ? "pt-16 overflow-y-scroll scroll-container bg-light dark:bg-dark"
                  : "pt-12 bg-light dark:bg-dark"
              }
            >
              {children}
            </div>
          </div>
        </>
      ) : (
        children
      )}
    </div>
  );
}
