import React, { useRef } from "react";
import ReactSelect from "react-select";

export const ReactMultiSelect = (props) => {
  // isOptionSelected sees previous props.value after onChange
  const valueRef = useRef(props.value);
  valueRef.current = props.value;
  const selectAllOption = {
    [props.valueProps]: "<SELECT_ALL>",
    [props.labelProps]: "Select All",
  };

  const isSelectAllSelected = () =>
    valueRef.current?.length === props?.options?.length;

  const isOptionSelected = (option) =>
    valueRef.current?.some((val) => val[props.valueProps] === option[props.valueProps]) ||
    isSelectAllSelected();

  const getOptions = () => [selectAllOption, ...props.options];

  const getValue = () =>
    isSelectAllSelected() ? [selectAllOption] : props.value;

  const onChange = (newValue, actionMeta) => {
    const { action, option, removedValue } = actionMeta;

    if (action === "select-option" && option[props.valueProps] === selectAllOption[props.valueProps]) {
      props.onChange(props.options, actionMeta);
    } else if (
      (action === "deselect-option" && option[props.valueProps] === selectAllOption[props.valueProps]) ||
      (action === "remove-value" && removedValue[props.valueProps] === selectAllOption[props.valueProps])
    ) {
      props.onChange([], actionMeta);
    } else if (
      actionMeta.action === "deselect-option" &&
      isSelectAllSelected()
    ) {
      props.onChange(
        props.options.filter((val) => val[props.valueProps] !== option[props.valueProps]),
        actionMeta
      );
    } else {
      props.onChange(newValue || [], actionMeta);
    }
  };

  return (
    <>
    <ReactSelect
      isOptionSelected={isOptionSelected}
      options={getOptions()}
      value={getValue()}
      onChange={onChange}
      getOptionLabel={(options) => options[props.labelProps]}
      getOptionValue={(options) => options[props.valueProps]}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      isMulti
      // menuPosition="fixed"
      defaultValue={props.defaultValue}
      placeholder={props.placeholder}
      className={props.className}
    />
    {props?.errors!== undefined && props?.errors?.length > 0 &&
        props?.errors?.map((error, index) => {
          return (
            <>
              {error[props?.name] && (
                // <div key={index} className="text-[#f00] text-sm mb-3.7 mt-1.5">{error[name].replaceAll(name,placeholder)}</div>

                <small
                  style={{
                    fontSize: 12,
                    marginTop: 0,
                    paddingLeft: 16,
                    color: "#D32F2F",
                    background:'transparent'
                  }}
                  key={index}
                >
                  {error[props?.name].replaceAll(`"${props?.name}"`, props?.label).replaceAll(`${props?.name}`, props?.label)}
                </small>
              )}
            </>
          );
        })}
      </>
  );
};
