import {
  HiUserCircle,
  HiUserGroup,
  HiUsers,
  HiViewGrid,
  HiLink,
  HiCog,
  HiDatabase,
  HiClock,
  HiStar,
  HiTag,
  HiGlobe,
} from "react-icons/hi";

export const sideMenu = [
  {
    to: "/dashboard",
    label: "Dashboard",
    Icon: HiViewGrid,
  },
  {
    to: "/menu",
    label: "Menu",
    Icon: HiTag,
  },
  {
    to: "/users",
    label: "Users",
    Icon: HiUserGroup,
  },
  {
    to: "/role",
    label: "Role",
    Icon: HiUserCircle,
  },
  // {
  //   to: "/customers",
  //   label: "Customers",
  //   Icon: HiLink,
  // },
  
  // {
  //   to: "/dashboard/domains",
  //   label: "Domains",
  //   Icon: HiGlobe,
  // },
  // {
  //   to: "/dashboard/settings",
  //   label: "Settings",
  //   Icon: HiCog,
  //   children: [
  //     {
  //       to: "/dashboard/settings/merchants",
  //       label: "Merchants",
  //       Icon: HiUsers,
  //     },
  //     {
  //       to: "/dashboard/settings/users",
  //       label: "Users",
  //       Icon: HiUserGroup,
  //     },

  //     {
  //       to: "/dashboard/settings/industry",
  //       label: "Industry",
  //       Icon: HiDatabase,
  //     },
  //     {
  //       to: "/dashboard/settings/hours",
  //       label: "hours",
  //       Icon: HiClock,
  //     },
  //     {
  //       to: "/dashboard/settings/membership",
  //       label: "membership",
  //       Icon: HiUserCircle,
  //     },
  //     {
  //       to: "/dashboard/settings/rating",
  //       label: "rating",
  //       Icon: HiStar,
  //     },
  //     {
  //       to: "/dashboard/settings/status",
  //       label: "status",
  //       Icon: HiTag,
  //     },
  //   ],
  // },
];
