import {
  EnvelopeIcon,
  MapPinIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import {
  FaInstagram,
  FaTwitter,
  FaPinterest,
  FaGoogle,
  FaFacebookF,
} from "react-icons/fa";
import React from "react";

export default function Footer() {
  return (
    <div className="py-16 flex items-center justify-center bg-dark text-white">
      <div className="flex items-center justify-center flex-col w-10/12">
        <img src="/logoWhite.png" alt="" className="h-16" />
        <p className="mt-5 lg:w-6/12 text-center text-gray-400">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum
          inventore hic animi fugit! Quia tenetur repellat aperiam dolor ducimus
          maxime, accusamus ipsa porro exercitationem, totam quidem fugiat
          molestiae praesentium omnis!
        </p>
        <button className="btnPrimary mt-5 text-lg py-3 px-7 rounded-full">
          Get a quote
        </button>
        <div className="flex justify-between mt-16 pt-7 border-t border-white/25 w-full text-gray-400">
          <div>
            <span className="flex gap-2 mb-2">
              <MapPinIcon className="w-5" />
              <p>Los Angeles California</p>
            </span>
            <span className="flex gap-2 mb-2">
              <EnvelopeIcon className="w-5" />
              <p>support@roboanywhere.com</p>
            </span>
            <span className="flex gap-2 mb-2">
              <PhoneIcon className="w-5" />
              <p>+1 800 854-36-80</p>
            </span>
          </div>
          <div className="flex flex-col items-end">
            <div className="flex items-center justify-end gap-3">
              <span className="border p-3 rounded-full cursor-pointer">
                <FaFacebookF className="text-2xl" />
              </span>
              <span className="border p-3 rounded-full cursor-pointer">
                <FaInstagram className="text-2xl" />
              </span>
              <span className="border p-3 rounded-full cursor-pointer">
                <FaTwitter className="text-2xl" />
              </span>
              <span className="border p-3 rounded-full cursor-pointer">
                <FaPinterest className="text-2xl" />
              </span>
              <span className="border p-3 rounded-full cursor-pointer">
                <FaGoogle className="text-2xl" />
              </span>
            </div>
            <p className="mt-5">All rights reserved © 2023 roboanywhere</p>
          </div>
        </div>
      </div>
    </div>
  );
}
