import { PhoneIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";
import { Link } from "react-scroll";
import { new_logo } from "../../assets/images";
import { FullContainer, InputField } from "../../components";
import { useDispatch } from "react-redux";
import { login } from "../../redux/authSlice";
import { NavLink } from "react-router-dom";
export default function LoginForm() {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userData = {
      email: username,
      password,
    };
    dispatch(login( userData ));
  };

  return (
    <FullContainer className="flex">
      <div id="loginForm" className="grid grid-cols-login w-full">
        <div className="bg-login bg-cover bg-right-top h-screen">
          <nav className="flex items-center p-6 text-white">
            <img src="/logoWhite.png" className="h-11" alt="" />
            <div className="flex items-center ml-7 font-semibold">
              <button className="pageLink px-4">Home</button>
              <Link
                spy={true}
                smooth={true}
                to="AboutUs"
                className="pageLink px-4"
              >
                About
              </Link>
              <Link
                spy={true}
                smooth={true}
                to="pricing"
                className="pageLink px-3 hover:bg-secondary"
              >
                Pricing
              </Link>
              <Link
                spy={true}
                smooth={true}
                to="FAQs"
                className="pageLink px-3 hover:bg-secondary"
              >
                FAQs
              </Link>
              <a
                href="tel:+1 (800) 542-2323"
                className="pageLink px-3 hover:bg-secondary gap-1"
              >
                <PhoneIcon className="h-4" />
                +1 (800) 542-2323
              </a>
            </div>
          </nav>
        </div>
        <div className="p-8 rounded-md">
          <img src={new_logo} className="h-20 ml-auto mr-auto" alt="" />
          <div className="py-10 w-full">
            <h1 className="text-2xl font-bold">Hi, Welcome Back!</h1>
            <p className="text-gray-500">
              Logical Customer Relation Manager Solution
            </p>
          </div>
          <form onSubmit={handleSubmit} className="w-full space-y-4">
            <InputField
              name="username"
              label="username"
              type="text"
              placeholder="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <InputField
              name="password"
              label="Password"
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <p className="text-yellow-500 text-sm mt-3 text-end underline hover:text-yellow-600">
              Forgot Password?
            </p>
            <button
              className="btnPrimary w-full mt-5 shadow-secondary/50 shadow-lg"
              type="submit"
            >
              Login Now
            </button>
            <span className="py-3 grid grid-cols-or items-center text-center">
              <div className="border h-[2px] w-full"></div>
              <p>or</p>
              <div className="border h-[2px] w-full"></div>
            </span>

            <NavLink
              to="/register"
              // className="btnPrimary w-full mt-5 border border-secondary bg-white shadow-none text-secondary"
              // type="submit"
            >
              <button
              className="btnPrimary w-full mt-5 border border-secondary bg-white shadow-none text-secondary"
              type="submit"
            >
              Sign up
            </button>
            </NavLink>
            {/* <button
              className="btnPrimary w-full mt-5 border border-secondary bg-white shadow-none text-secondary"
              type="submit"
            >
              Sign up
            </button> */}
          </form>
        </div>
      </div>
    </FullContainer>
  );
}
