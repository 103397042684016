import axios from "axios";

const API = axios.create({
  baseURL:"https://vtapi.ecommcube.com",
    headers: {
        'Access-Control-Allow-Origin': '*',
        "Content-Type": "application/json",
    }
});
API.interceptors.request.use(
    async (config) => {
      const { accessToken } = localStorage;
  
      if (accessToken) {

          config.headers.Authorization = `Bearer ${accessToken}`;
        } 
        return config;
      },
    (error) => {
      console.error("Error to call API", error);
    }
  );

export default API