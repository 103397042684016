import API from "./api";


export const getMenu = async () => {
    return await API.get("/settings/menu/getAll");
  };
  export const addMenu = async (data) => {
    return await API.post("/settings/menu/createOne",data);
  };
  export const updateMenu = async (data) => {
    return await API.post(`/settings/menu/updateOne`,data);
  };
  export const deleteMenu = async (id) => {
    
    return await API.post(`/settings/menu/deleteOne`,{id});
  };
  export const getPermission = async () => {
    return await API.get("/settings/permission");
  };