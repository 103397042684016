const InputField = ({
  name,
  label,
  onChange,
  onBlur,
  type,
  disabled = false,
  placeholder,
  value,
  required,
  max,
  min,
}) => {
  return (
    <div className="w-full">
      <label className="inputLabel" htmlFor={name}>
        {label}
      </label>
      <input
        type={type || "text"}
        disabled={disabled}
        name={name}
        max={max}
        min={min}
        placeholder={placeholder}
        className="inputField mt-1"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        required={required}
      />
    </div>
  );
};

export default InputField;
