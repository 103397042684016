import './App.css';
import React from "react"
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import Signup from './views/Authentication/registration';
import ForgetPassword from './views/Authentication/forgetPassword';
import Login from './views/Login'
import Layout from './layout';
import Main from './views/Dashboard/Main';
import MenuManagement from './views/MenuManagement';
import UserManagement from './views/UsersManagement';
import RoleManagement from './views/RoleManagement';
function App() {
  const { user } = useSelector((state) => state.auth);
  let routes;
  if (!user) {
    routes = [
      { path: "/login", element: <Login /> },
      { path: "/register", element: <Signup /> },
      { path: "/forget_password", element: <ForgetPassword /> },
      { path: "*", element: <Navigate to="/login" replace /> },
    ];
  } else {
    routes = [
      { path: "/dashboard", element: <Main /> },
      { path: "/registration", element: <Signup /> },
      { path: "/password_reset", element: <ForgetPassword /> },
      { path: "/menu", element: <MenuManagement /> },
      { path: "/users", element: <UserManagement /> },
      { path: "/role", element: <RoleManagement /> },
      { path: "*", element: <Navigate to="/dashboard" replace /> },
    ];
  }
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          {routes.map((route, index) => (
            <Route key={index} {...route} />
          ))}
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
