import React, { useEffect, useState } from "react";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";

import AddRole from "./AddRole";


import { delete_role, get_role } from "../../redux/roleSlice";
import { toast } from "react-toastify";
import DotsLoader from "../../components/atom/DotsLoader";
export default function RoleManagement() {
  const dispatch = useDispatch();
  const { isLoading, role } = useSelector((state) => state.role);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [assignMenuModal, setAssignMenuModal] = useState(false);


  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
    setAssignMenuModal(false);
  };
  useEffect(() => {
    dispatch(get_role());
    
  }, []);
  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_role(record_id));
        console.log("🚀 ~ file: index.jsx:35 ~ deleteRecordHandler ~ res:", res)
        if (res.payload.status === 201) {
          toast.success("Role deleted");
          dispatch(get_role());
        } else {
          toast.error("Role couldn't be deleted");
        }
      } catch (error) {
        console.log("🚀 ~ file: index.jsx:42 ~ deleteRecordHandler ~ error:", error)
        toast.error("Role couldn't be deleted");
      }
    }
  };
  return (
    <div className="p-5">
      {isEditing && (
        <AddRole
          editingRecord={editingRecord}
          modalTitle="Add Users"
          onCancelForm={cancelFormHandler}
        />
      )}
      {assignMenuModal && (
        <AddRole
          editingRecord={editingRecord}
          modalTitle="Add Users"
          onCancelForm={cancelFormHandler}
        />
      )}
      {isLoading&&<DotsLoader/>}
      <div className="flex items-center justify-between mt-2">
        <h1 className="elementHeading2">Role Management</h1>
        <div>
          <button className="btnPrimary" onClick={() => setAssignMenuModal(true)}>
            Add Role
          </button>
        </div>
      </div>
      <div className="w-full overflow-y-scroll scroll-container h-[calc(100vh-200px)] mt-6">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-200 dark:bg-blue sticky top-0 shadow-sm ">
            <tr>
              <th scope="col" className="tableHead">
                Sr.
              </th>
              <th scope="col" className="tableHead">
                Name
              </th>
              
              <th scope="col" className="tableHead">
                Permissions
              </th>
              <th scope="col" className="tableHead">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 h-full overflow-y-scroll">
            {role?.map((user, index) => (
              <tr
                key={index}
                className="hover:bg-gray-200 dark:hover:bg-blue/70"
              >
                <td className="p-3">
                  <div className="text-sm">{index + 1}</div>
                </td>
                <td className="p-3">
                  <div className="text-sm">{user?.name}</div>
                </td>
               
                <td className="p-3">
                  {user?.permissions?.map((item, index) => {
                    return (
                      <div className="text-sm" key={index}>
                        {item?.description}
                      </div>
                    );
                  })}
                </td>
                <td className="p-3">
                  <span className="h-full w-full flex items-center">
                    <button
                      className="text-gray-400 hover:text-yellow-500"
                      onClick={openFormHandler(user)}
                    >
                      <PencilSquareIcon className="h-4" />
                    </button>
                    <button className="ml-2 text-gray-400 hover:text-red-400"
                     onClick={deleteRecordHandler(user?.id)}>
                      <TrashIcon className="h-4" />
                     
                    </button>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
