import React from "react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { BiLogOut, BiSun, BiMoon } from "react-icons/bi";

export default function EclipseDropdown({ toggleDarkMode, darkToggle }) {
  return (
    <div className="navDropdownButton relative">
      <div className="navDropdown flex-col w-[200px] items-center absolute top-0 right-0 mt-7 p-2 bg-white dark:bg-blue rounded-md capitalize shadow-xl shadow-black/25">
        <button
          onClick={toggleDarkMode}
          className="px-4 py-2 hover:rounded border-b text-left hover:bg-gray-100 dark:hover:bg-white/10 w-full transition-all flex items-center justify-between"
        >
          {darkToggle ? "Light Mode" : "Dark Mode"}
          {darkToggle ? <BiSun className="text-yellow-600" /> : <BiMoon />}
        </button>
        <button
          onClick={() => {
            localStorage.clear();
            window.location.reload();
          }}
          className="px-4 py-2 hover:rounded hover:bg-gray-100 dark:hover:bg-white/10 w-full transition-all flex items-center justify-between text-red-500"
        >
          Logout
          <BiLogOut />
        </button>
      </div>
      <EllipsisVerticalIcon
        title="dots icon"
        alt="dots icon"
        className="h-7 ml-5 cursor-pointer"
      />
    </div>
  );
}
