import API from "./api";


export const getRole = async () => {
    return await API.get("/settings/role/getAll");
  };
  export const addRole = async (data) => {
    return await API.post("/settings/role/createOne",data);
  };
  export const updateRole = async ({id,data}) => {
  
    return await API.post(`/settings/role/updateOne`,{...data,id});
  };
  export const deleteRole = async (id) => {
    
    return await API.post(`/settings/role/deleteOne`,{id});
  };