import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from "./Dropdown";
import { sideMenu } from "./Menu";
import { BiLogOut } from "react-icons/bi";
import { MoonIcon, SunIcon } from "@heroicons/react/24/solid";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../redux/authSlice";
import { useNavigate } from "react-router-dom";
const Sidebar = ({ show_navlabel, toggleDarkMode, darkToggle }) => {
  const [activeMenuItem, setActiveMenuItem] = useState("Dashboard");
const dispatch=useDispatch()
const navigate = useNavigate();
  //to keep track of the active menu item even page refreshes
  useEffect(() => {
    const path = window.location.pathname;
    const label = sideMenu.find((item) => item?.to === path);
    if (label) {
      setActiveMenuItem(label.label);
    }
  }, []);
  return (
    <div className="p-3 pt-24 bg-white dark:bg-blue dark:text-white shadow-r dark:shadow-gray-800">
      <div className="flex flex-col justify-between h-full">
        <div>
          {sideMenu.map((item, key) => {
            const { label, to, Icon, children } = item;
            const isActive = activeMenuItem === item.label;

            return (
              <div key={key} className="sidebar-item">
                {item?.children ? (
                  <Dropdown
                    show_navlabel={!!show_navlabel}
                    label={label}
                    activeMenuItem={activeMenuItem}
                    setActiveMenuItem={setActiveMenuItem}
                    to={to}
                    Icon={Icon}
                    options={children}
                  />
                ) : (
                  <Link
                    className={`pageLink ${
                      isActive
                        ? show_navlabel
                          ? "bg-secondary hover:bg-secondary text-white"
                          : "flex items-center justify-center"
                        : show_navlabel
                        ? "dark:hover:text-white hover:bg-blue/10 dark:hover:bg-white/10"
                        : "flex items-center justify-center "
                    }`}
                    title={label}
                    to={to}
                    onClick={() => setActiveMenuItem(item.label)}
                  >
                    <Icon />
                    {show_navlabel && <span className="ml-3">{label}</span>}
                  </Link>
                )}
              </div>
            );
          })}
        </div>

        <div>
          {/* <button onClick={toggleDarkMode} className="pageLink hover:bg-blue/10 dark:hover:bg-white/10">
            {darkToggle ? <SunIcon className="text-yellow-400 mr-3 w-5" /> : <MoonIcon className="w-4 mr-3" />}
            {darkToggle ? "Light Mode" : "Dark Mode"}
          </button> */}
          <Link
            onClick={() => {
              dispatch(logoutUser())
              navigate("/login");
            }}
            className={
              show_navlabel
                ? "text-red-400 pageLink hover:bg-blue/10 dark:hover:bg-white/10"
                : "text-red-400 pageLink flex items-center justify-center"
            }
          >
            <BiLogOut />
            {show_navlabel && <span className="ml-3">Logout</span>}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
