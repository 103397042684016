import React from "react";

export default function AboutUs() {
  return (
    <div id="AboutUs"  className="flex items-center justify-center py-5">
      <div className="grid lg:grid-cols-about w-10/12 gap-10">
        <div className="flex flex-col justify-center">
          <h2 className="text-4xl font-bold text-dark">About Us</h2>
          <p className="text-2xl py-5 font-bold">
            We work with all kinds of customer requests and will tailor our
            services to perfectly match your needs.{" "}
          </p>
          <p className="text-gray-500">
            Lorem Ipsum has been the industry's standard dummy text ever since
            the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book.
          </p>
          <br />
          <p className="text-gray-500">
            Lorem Ipsum has been the industry's standard dummy text ever since
            the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book.
          </p>
          <button className="btnPrimary bg-dark mt-8 py-3 px-8 text-lg text-white w-fit">
            Get Started
          </button>
        </div>
        <div>
          <img src="/dashboard.png" className="w-full" alt="" />
        </div>
      </div>
    </div>
  );
}
