import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";

export default function SearchBox({ className }) {
  return (
    <div
      className={`relative w-full ${className}`}
    >
      <div className="flex items-center w-full appearance-none px-3 flex-1">
        <MagnifyingGlassIcon className="w-5" />
        <input
          placeholder="Search.."
          className="bg-transparent flex-1 w-full outline-none ml-3 py-2 appearance-none"
        />
      </div>
    </div>
  );
}
