import API from "./api";


export const getUsers = async () => {
    return await API.get("/user?join=roles");
  };
//   export const addMenu = async (data) => {
//     return await API.post("/users",data);
//   };
  export const updateUsers = async ({id,roles}) => {
    
    console.log("updateuserrr",id,roles)
    return await API.patch(`/user/${id}`,{roles});
  };
  export const deleteUsers = async (id) => {
    
    return await API.delete(`/users/${id}`);
  };