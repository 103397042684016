import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  
  getUsers,updateUsers,deleteUsers
} from "../services";


const initialState = {
  isError: false,
  isLoading: false,
  users: [],
  message: "",
  isLoader: false,
};
// Get Menu Data
export const get_users = createAsyncThunk(
  "get_users",
  async (thunkAPI) => {
    try {
      return await getUsers();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Add New User
// export const add_menu = createAsyncThunk(
//   "add_menu",
//   async (data, thunkAPI) => {
//     try {
//       return await addMenu(data);
//     } catch (error) {
//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );

// Assign Staff
export const update_users = createAsyncThunk(
  "update_users",
  async (data, thunkAPI) => {
    try {
      return await updateUsers(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete User
export const delete_users = createAsyncThunk(
  "delete_users",
  async (id, thunkAPI) => {
    try {
      return await deleteUsers(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.customers = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_users.pending, (state) => {
        state.isLoading = true;
        state.isLoader = true;
        state.users = [];
      })
      .addCase(get_users.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoader = false;
        state.users = action.payload?.data;
      })
      .addCase(get_users.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoader = false;
        state.isError = true;
        state.message = action.payload;
        state.users = [];
      })
    //   .addCase(add_menu.pending, (state) => {
    //     state.isLoading = true;
    //   })
    //   .addCase(add_menu.fulfilled, (state, action) => {
    //     state.isLoading = false;
    //     state.users=action.payload.data;
    //   })
    //   .addCase(add_menu.rejected, (state, action) => {
    //     state.isLoading = false;
    //   })
      .addCase(update_users.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_users.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.users.push(action?.payload?.data)
        const result = state.users.findIndex(
            ({ id }) => id === action?.payload?.data?.id
          );
          state.users[result] = {
            ...state.users[result],
            ...action.payload.data,
          };
      })
      .addCase(update_users.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_users.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_users.fulfilled, (state, action) => {
        state.isLoading = false;
        // const result = state.menu.findIndex(
        //   ({ id }) => id === action?.payload?.data?.id
        // );
        // state.menu[result].active = false;
      })
      .addCase(delete_users.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { reset } = userSlice.actions;
export default userSlice.reducer;
