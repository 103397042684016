import React from "react";

const InputField = ({
  name,
  label,
  onChange,
  type,
  disabled = false,
  placeholder,
  value,
}) => {
  return (
    <div className="w-full">
      <label className="inputLabel" htmlFor={name}>
        {label}
      </label>
      <input
        type={type || "text"}
        disabled={disabled}
        name={name}
        placeholder={placeholder}
        className="inputField mt-1 p-2  "
        value={value}
        onChange={onChange}
        // onBlur={onBlur}
      />
    </div>
  );
};

export default InputField;
