import React from "react";

export default function Status({ type }) {
  return (
    <>
      {type === "success" && (
        <div className="p-2 font-semibold rounded-md bg-green-100 text-green-600 text-center px-4">
          Status
        </div>
      )}
      {type === "pending" && (
        <div className="p-2 font-semibold rounded-md bg-red-100 text-red-600 text-center px-4">
          Pending
        </div>
      )}
    </>
  );
}
