import React from "react";
import { Link } from "react-router-dom";
import Checkbox from "./common/CheckBox";

export default function Cart() {
  return (
    <div className="w-full text-black flex flex-col">
      <h5 className="text-lg text-center font-bold">Added to cart</h5>
      <div className="p-3 rounded-md bg-white shadow-md">
        <div className="flex item-center justify-between pb-3 border-b">
          <p>towingwiz.com</p>
          <p className="font-semibold">$95.00</p>
        </div>
        <div className="flex item-center justify-between py-3">
          <p className="font-semibold">Amount</p>
          <p>$0.00</p>
        </div>
        <div className="flex item-center justify-between">
          <p className="font-semibold">Sub Total</p>
          <p>$3,439.00</p>
        </div>
      </div>
      <Link to="/dashboard/marketplace/order">
        <button className="btnPrimary mt-3 w-full text-white">
          Proceed to checkout
        </button>
      </Link>
      <div className="mt-3 bg-secondary/10 border-secondary border-2 border-dashed rounded-md text-xs py-2 px-3">
        <Checkbox label="Content creation for your links is Included in the price" />
      </div>
    </div>
  );
}
