import API from "./api";

export const login = async (data) => {
  return await API.post(`/auth/login`, data);
};
export const signup = async (data) => {
  return await API.post(`/auth/register`, data);
};
export const logout = async () => {
  return await API.get(`/api/logout`, {
    headers: {
      refreshToken: localStorage.getItem("refreshToken"),
    },
  });
};

export const sendResetEmail = async (email) => {
  return await API.post(`/api/auth/recover`, email);
};