import React from "react";
import { user1, user2, user3 } from "../../assets/images";

export default function Testimonials() {
  return (
    <div id="testimonial" className="flex items-center flex-col justify-center py-16 bg-[#F6F7FD] mt-10">
      <div className="flex flex-col items-center w-10/12 text-center">
        <p className="uppercase font-medium">Testimonials</p>
        <h2 className="text-4xl font-bold capitalize mt-3">
          What our clients say about us.
        </h2>
        <div className="w-full grid lg:grid-cols-3 gap-4 mt-7">
          <Testimonial img={user1} />
          <Testimonial img={user2} />
          <Testimonial img={user3} />
        </div>
        <h5 className="text-lg font-bold text-dark mt-12">
          Our Clients Love Us
        </h5>
        <p className="mt-2 w-96 text-gray-500">
          You can feel confident in choosing our product. At the end of the day,
          our commitment to excellence and customer satisfaction is at the heart
          of everything we do.
        </p>
        <button className="btnPrimary text-white py-3 bg-dark mt-5">
          Take a Demo
        </button>
      </div>
    </div>
  );
}

function Testimonial({ img }) {
  return (
    <div className="bg-white shadow-xl rounded-lg p-7 flex flex-col items-center">
      <img src="/quote.png" alt="" className="h-8" />
      <p className="text-gray-500 mt-5">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, dolores
        quidem neque ea rem a saepe sed ad? Aliquid obcaecati
      </p>
      <img src={img} alt="" className="mt-5 mb-1 w-16" />
      <p>John Doye</p>
    </div>
  );
}
